<template>
  <div id="model" :ref="'model'">
    <mapToolbar
      @toggleRotate="toggleRotate"
      :toggleRotateFlag="toggleRotateFlag"
      @randomIteration="randomIteration"
      @changeAmbientLightColor="changeAmbientLightColor"
      @toggleContext="toggleContext"
    />

    <div ref="modelView" id="modelView"></div>

    <inputSelector :ref="'inputSelector'" @callRemoveModel="removeModel" />

    <inputPanel />

    <dragDrop v-if="showDragDrop" @setDragDropFlag="setDragDropFlag" />
  </div>
</template>

<script>
/**
 * @vue-import inputselector child component
 *
 */
import inputSelector from "@/components/explore/inputSelector.vue";
/**
 * @vue-import inputpanel child component
 */
import inputPanel from "@/components/explore/inputPanel.vue";
/**
 * @vue-import dragDrop component
 */
import dragDrop from "@/components/dragDrop/dragDrop.vue";
/**
 * @component
 */
import mapToolbar from "@/components/explore/map_toolbar.vue";
/**
 * @vue-import store mapping store to view
 */
import { mapGetters } from "vuex";

// import helper functions
import {
  ConstructScene,
  colorMesh,
  Lights,
  getRandomIterationID,
  disposeScene
} from "@/assets/js/helper.js";

import { exploreModeSettings } from "@/assets/js/library.js";
/**
 * import THREEjs library
 */
const THREE = require("three");
/**
 * import orbit controls library
 */
const OrbitControls = require("three-orbit-controls")(THREE);
/**
 * import tweenjs library
 */
const TWEEN = require("@tweenjs/tween.js").default;

let scene = null;
let lights = null;
let camera = null;
let renderer = null;
let controls = null;
const loader = new THREE.ObjectLoader();

export default {
  name: "model",
  data() {
    return {
      currentModelObject: false,
      /**
       * @type {Boolean}
       * switches rotate
       */
      toggleRotateFlag: false,
      /**
       * @type {Boolean}
       * switches whether initial controls and scene settings have been saved
       */
      controlsSetFlag: false,
      /**
       * @type {Boolean}
       */
      showDragDrop: false
    };
  },
  components: {
    inputSelector: inputSelector,
    inputPanel,
    dragDrop,
    mapToolbar
  },
  created() {
    /**
     * @type dispatch
     * calls store to pull data.csv + settings.json
     * ROOT APPLICATION START
     * APPLICATION STARTS WITH readModelData as an init point
     *
     */
    const location = window.location.href;

    if (this.modelDataFlag === false) {
      this.scoutMode("custom");
      // if (
      //   location.indexOf("https://scoutbeta.kpfui.dev/?project") !== -1 ||
      //   location.indexOf("https://scout.kpfui.dev/?project") !== -1
      // ) {
      //   this.$store.dispatch("readModelData", this.$route.query);
      // } else if (
      //   location === "https://scoutbeta.kpfui.dev/" ||
      //   location === "https://scout.kpfui.dev/"
      // ) {
      //   this.scoutMode("public");
      // } else if (location.indexOf("localhost") !== -1) {
      //   // this.scoutMode("public")
      //   this.scoutMode("custom");
      // }
    }
  },
  mounted() {
    /**
     * init the scene with container, scene, camera, renderer
     */
    this.init();

    /**
     * reloads scene from saved settings
     *
     */
    if (this.modelDataFlag === true) {
      // load inital settings from public folder
      this.reloadingContext(this.$route.params["filterUpdate"] === true);
    }
    /**
     * Subscribe to the Vuex Store
     */
    this.subscribeToStore();

    /**
     * start animation loop
     */
    this.animate();
  },
  beforeDestroy() {
    /**
     * Unsubscribe from the Vuex Store
     */
    this.unsubscribeFromStore();

    this.$store.commit("resetMetricToggles", true);
    this.$store.commit("setMetricSelected", null);
  },
  destroyed() {
    // destory the animation loop
    cancelAnimationFrame(this.animationFrameID);

    window.removeEventListener("resize", this.onWindowResize, false);

    disposeScene(scene, renderer);
  },
  filters: {
    rotateTextFilter(toggle) {
      return toggle === true ? "Stop" : "Play";
    }
  },
  computed: {
    ...mapGetters({
      selectedModel: "getSelectedModel",
      metricSelected: "getMetricSelected",
      metricObject: "getMetricObject",
      numberOfModels: "getNumberOfModels",
      contextObject: "getContextObject",
      modelSettings: "getModelSettings",
      cameraRadius: "getCameraRadius",
      modelDataFlag: "getModelDataFlag",
      modelID: "getModelID",
      metric: "getMetric",
      settings: "getSettingsData",
      defaultFlag: "getDefaultFlag",
      projectLocation: "getProjectLocation",
      projectScale: "getProjectScale",
      camerPosition: "getCameraPosition"
    }),
    /**
     * @returns document height minus navbar height
     */
    getCanvasHeight() {
      return document.getElementById("navbar").getBoundingClientRect().height;
    },
    perspectiveView() {
      return {
        x: this.cameraRadius,
        y: this.cameraRadius,
        z: -this.cameraRadius
      };
    },
    container() {
      return this.$refs["modelView"];
    }
  },
  methods: {
    scoutMode(mode) {
      switch (mode) {
        case "custom":
          this.$store.dispatch("readModelData", { project: "hawaii" });
          break;

        case "public":
          this.setDragDropFlag(true);
          this.$store.commit("setLoadingFlag", false);
          this.$store.commit("setExplorePanel", ["Controls", false]);
          this.$store.commit("setScoutMode", "public");

          break;
      }
    },
    /**
     * @param {Boolean} toggle
     *  shows/hides context layer
     */
    toggleContext(toggle) {
      this.contextObject.traverse(function(child) {
        if (child instanceof THREE.Mesh) {
          child.visible = toggle;
        }
      });
    },
    /**
     * @param {Boolean} toggle
     * show drag + drop card
     */
    setDragDropFlag(toggle) {
      this.showDragDrop = toggle;
    },
    /**
     * @param object json model
     * @param addmodel callback function to add model to scene
     * @param onProgress callback progress function
     * @param projectScale int between 0 - 1
     */
    loadObject(object, addModel, onProgress, projectScale) {
      loader.parse(object, obj => {
        obj.scale.set(projectScale, projectScale, projectScale);

        addModel(obj);
      });
    },
    /**
     * switches autorotate on/off
     * controls.autorotate turns off scene rotation
     * togglerotateflg is global flag for UI
     */
    toggleRotate() {
      controls.autoRotate = !controls.autoRotate;
      this.toggleRotateFlag = controls.autoRotate;
    },
    /**
     * Generates value between 0 - maximum number of iterations of dataset
     *  0 - maximum number of possible iterations in model set
     *  once genereated submits value to app store
     */
    randomIteration() {
      // let randomGen = Math.floor(Math.random() * this.numberOfModels.length - 1)
      let randomGen = getRandomIterationID(this.numberOfModels);
      this.$store.commit(
        "setSearchIterationID",
        this.numberOfModels[randomGen]
      );
    },
    /**
     * inits the scene container
     * sets width and height of scene
     * fires light function
     * creates renderer constrcutor
     * sets up camera
     * creates window event listener for resizing
     */
    init() {
      /**
       * @type {class}
       * instantiates the scene as a class object
       */
      const createScene = new ConstructScene(
        THREE.Scene,
        new THREE.Color("rgb(24,24,24)"),
        new THREE.Fog(0x1a2050, 10000, 10000)
      );
      scene = createScene.scene;

      createScene.constructRenderer(
        THREE.WebGLRenderer,
        this.getCanvasHeight,
        THREE.PCFSoftShadowMap
      );
      renderer = createScene.renderer;

      createScene.constructCamera(
        THREE.PerspectiveCamera,
        this.getCanvasHeight
      );
      camera = createScene.camera;

      createScene.constructControls(OrbitControls, renderer.domElement);
      controls = createScene.controls;

      // setup lights
      lights = new Lights();
      lights.createDirectionalLight();

      scene.add(lights.ambient);
      scene.add(lights.directionalLight);
      scene.add(lights.directionalLight2);

      this.container.appendChild(renderer.domElement);

      // window resize event
      window.addEventListener("resize", this.onWindowResize, false);
    },
    /**
     * animation loop
     * tween.update allows for camera tween motion
     */
    animate() {
      // assign ID to the animation frame to use for destruction
      this.animationFrameID = requestAnimationFrame(this.animate);

      TWEEN.update();
      controls.update();

      renderer.render(scene, camera);

      // set current camera position to saved setting
      exploreModeSettings["camera"]["position"] = camera.position;
    },
    /**
     * removes the current model based on name attribute
     * where the number is model iteration followed by _option
     *  @param modelName as {#}_option
     */
    removeModel: function(modelName) {
      console.debug("Removing model from scene", modelName);

      let selectedObject = scene.getObjectByName(modelName);

      selectedObject.children.forEach(function(child, i) {
        child.geometry.dispose();
        child.material.dispose();
        child = undefined;
      });

      scene.remove(selectedObject);

      selectedObject = undefined;

      renderer.dispose();
      renderer.renderLists.dispose();
    },
    /**
     * @param model json parased model
     * places model in the scene after load is complete
     */
    addModel: function(model) {
      console.debug("adding model to scene", this.modelID);

      // check to see if there is a model to remove
      if (this.currentModelObject) {
        if (scene.getObjectByName(this.currentModelObject.name) !== undefined) {
          this.removeModel(this.currentModelObject.name);
        }
        this.currentModelObject = false;
      }
      // check to see if model center context has been set
      // if false, then get center of model and set as model center
      if (this.controlsSetFlag === false) {
        const bb = new THREE.Box3();

        bb.expandByObject(model);

        var center = new THREE.Vector3();

        bb.getCenter(center);

        const modelSettings = {
          x: -center.x,
          y: center.y,
          z: -center.z
        };

        model.position.set(modelSettings.x, modelSettings.y, modelSettings.z);

        bb.expandByObject(this.contextObject);

        var ms = bb.getBoundingSphere(new THREE.Sphere());

        const cameraRadius = (ms.radius / 2) * (1 + Math.sqrt(5));

        this.contextObject.position.set(
          modelSettings.x,
          modelSettings.y,
          modelSettings.z
        );

        // controls.target.set(0, modelSettings.y, 0)

        // controls.update()

        this.$store.commit("setModelSettings", modelSettings);

        this.$store.commit("setCameraRadius", cameraRadius);

        camera.position.set(
          this.perspectiveView.x,
          this.perspectiveView.y,
          this.perspectiveView.z
        );

        scene.add(this.contextObject);

        this.controlsSetFlag = true;
      } else {
        model.position.set(
          this.modelSettings.x,
          this.modelSettings.y,
          this.modelSettings.z
        );
      }
      // update model active name

      model.name = this.modelID + "_option";

      this.currentModelObject = model;

      // add model
      scene.add(model);
    },
    moveCameraToPosition(cameraPosition) {
      if (controls.autoRotate) {
        controls.autoRotate = false;
        this.toggleRotateFlag = false;
      }
      // disable controls during animation
      controls.enabled = false;
      // get position
      const from = camera.position;
      let tween = new TWEEN.Tween(from).to(cameraPosition, 1500);
      tween.easing(TWEEN.Easing.Exponential.InOut);
      tween.start();

      // tween = new TWEEN.Tween(controls.target).to({ x: 0, y: 0, z: 0 }, 1500)
      // tween.easing(TWEEN.Easing.Sinusoidal.InOut)
      // tween.start()
      // renable controls after animation
      controls.enabled = true;
    },
    /**
     * @output
     *  camera postion
     *  controls position
     *  add context object
     *  turn off loading after context is loaded
     *  @param bypass { Boolean } to load object directly or set by input values based on routing
     *
     */
    reloadingContext: function(bypass) {
      camera.position.set(
        this.perspectiveView.x,
        this.perspectiveView.y,
        this.perspectiveView.z
      );

      // controls.target.set(0, this.modelSettings.y, 0)

      // controls.update()

      scene.add(this.contextObject);

      if (!bypass) {
        this.loadObject(
          this.selectedModel,
          this.addModel,
          this.onProgress,
          this.projectScale
        );
      }
    },
    /**
     * @param contextObject scene context scene object saving to store
     * @output
     * name the context object
     * store context object in store
     */
    saveContext(contextObject) {
      this.$store.commit(
        "setContextObject",
        Object.assign(contextObject, { _isVue: true })
      );
    },
    /**
     * @param metricSelect metric object selected
     * @return String of selected metric name parsed
     */
    parseMetricName(metricSelected) {
      return "_" + metricSelected.split("out_")[1].toLowerCase();
    },
    /**
     * @param modelName { String } input model name #_<option>
     * @return returns iteration number
     */
    parseModelName(modelName) {
      return modelName.split("_option")[0];
    },
    /**
     * @param metric scene metric object
     * @output adds to scene
     */
    addMetric(metric) {
      console.debug("adding metric");

      Object.assign(metric, { _isVue: true });

      // assign current metric to local context
      const selectedMetric = this.metricObject[this.metricSelected.metric];

      // give metric a name #_<metric_name>
      metric.name = this.metricSelected.metric;
      // assign position of metric based on global model settings
      metric.position.set(
        this.modelSettings.x,
        this.modelSettings.y,
        this.modelSettings.z
      );

      this.$store.commit("setLoadingFlag", false);
      /**
       * @input
       * metric -> metric object
       * selectedMetric.ColorScale -> returns array of hex colors from min to max
       * this.defaultMaterial -> default THREE.js MeshBasicMaterial with white color
       * selectedMetric.materialType -> either MeshBasicMaterial or MeshStandardMaterial
       * THREE -> passing THREE Object
       * TO - DO
       *  - only send THREE.Mesh
       *  - only send THREE.Instance new
       *  @output
       * returns colored metric mesh
       * @note
       *  colorMesh is imported as a helper function
       */
      const coloredMetric = colorMesh(
        metric,
        selectedMetric.colorScale,
        this.defaultMaterial,
        selectedMetric.materialType,
        THREE.Color,
        THREE.MeshBasicMaterial,
        THREE.MeshStandardMaterial,
        THREE.Mesh,
        selectedMetric["zeroScale"]
      );

      Object.assign(coloredMetric, { _isVue: true });

      scene.add(coloredMetric);

      metric = undefined;
    },
    /**
     * @param toggle Boolean
     * @output turn current model in scene model shadow on/off
     */
    toggleModelShadow(toggle) {
      this.currentModelObject.receiveShadow = toggle;
      this.currentModelObject.castShadow = toggle;
    },
    changeAmbientLightColor(color, x, y, z) {
      var c = new THREE.Color(color);
      lights.directionalLight.position.set(x, y, z);
      lights.ambient.color = c;
    },
    /**
     * @output test screen width and adjust scene renderer
     */
    onWindowResize: function() {
      camera.aspect =
        window.innerWidth / (window.innerHeight - this.getCanvasHeight);
      camera.updateProjectionMatrix();

      renderer.setSize(
        window.innerWidth,
        window.innerHeight - this.getCanvasHeight
      );
    },
    /**
     * subscribe to VUEX store
     * https://dev.to/viniciuskneves/watch-for-vuex-state-changes-2mgj
     */
    subscribeToStore() {
      /**
       * @subscriber -> fire only when new model is selected
       */
      this.modelSelectedUnsubscribe = this.$store.subscribe(
        (mutation, state) => {
          switch (mutation.type) {
            case "setSelectedModel":
              this.loadObject(
                this.selectedModel,
                this.addModel,
                this.onProgress,
                this.projectScale
              );

              this.$store.commit("setLoadingFlag", false);

              break;
          }
        }
      );
      /**
       * @subscriber fire only when new metric parsed
       */
      this.metricUnsubscribe = this.$store.subscribe((mutation, state) => {
        switch (mutation.type) {
          case "setMetric":
            this.loadObject(
              this.metric,
              this.addMetric,
              this.onProgress,
              this.projectScale
            );

            break;
        }
      });
      /**
       * @subsciber fire only when new metric is selected
       */
      this.metricSelectedUnsubscribe = this.$store.subscribe(
        (mutation, state) => {
          switch (mutation.type) {
            case "setCameraPosition":
              this.moveCameraToPosition(this.camerPosition);
              break;
            case "setMetricSelected":
              /**
               * TURN OFF ANALYSIS
               */
              // checks metric status of the input metric as true or false
              if (
                this.metricSelected.status === false &&
                this.metricSelected.analysis_mesh === true
              ) {
                console.debug("turning off metric");

                this.$store.commit("setLoadingFlag", true);

                this.removeModel(this.metricSelected.metric);

                this.$store.commit("setLoadingFlag", false);
                /**
                 * TURN ON ANALYSIS
                 */
              } else if (
                this.metricSelected.status === true &&
                this.metricSelected.analysis_mesh === true
              ) {
                this.$store.commit("setLoadingFlag", true);
                // turn off building shadow when metric is loaded
                this.toggleModelShadow(false);
                // if the metric is in the scene remove it
                if (
                  scene.getObjectByName(this.metricSelected.metric) !==
                  undefined
                ) {
                  this.removeModel(this.metricSelected.metric);
                }

                // fetch analysis if analysis mesh exists
                if (this.metricSelected.analysis_mesh === true) {
                  this.$store.dispatch("readModelByID", {
                    ID:
                      this.modelID +
                      this.parseMetricName(this.metricSelected.metric),
                    type: "metric"
                  });

                  // this.$store.commit('setLoadingFlag', false)
                }
              }
              break;
          }
        }
      );
      /**
       * @subscriber fire only when new model data is ingested by store loop
       */
      this.contextUnsubscribe = this.$store.subscribe((mutation, state) => {
        switch (mutation.type) {
          case "setModelData":
            this.loadObject(
              this.contextObject,
              this.saveContext,
              this.onProgress,
              this.projectScale
            );
            break;
        }
      });
    },
    // unsubscribe from store mutations
    unsubscribeFromStore() {
      this.modelSelectedUnsubscribe();
      this.metricUnsubscribe();
      this.metricSelectedUnsubscribe();
      this.contextUnsubscribe();
    }
  }
};
</script>

<style lang="scss">
#modelView {
  canvas {
    height: calc(100% - #{$navbar-height});
    touch-action: none;
    z-index: -1;
    pointer-events: all;

    outline: none;
  }
}
</style>
